<!-- 查看商户余额列表 -->
<template>
  <listBalance
    :listBalanceEndpoint = listBalanceEndpoint
    :exportBalanceEndpoint = exportBalanceEndpoint
    :exportTransactionEndpoint = exportTransactionEndpoint
    :balanceDetailRouteName = balanceDetailRouteName
    :exportFilenamePrefix = exportFilenamePrefix
    :viewDetailPermission = viewDetailPermission
  />
</template>

<script>
import { FacilityActions } from '../../utils/actions';
import listBalance from "./components/listBalance";

export default {
  name: "licenseeBalance",
  components: {
    listBalance,
  },
  data() {
    return {
      listBalanceEndpoint: this.$endpoints.FINANCE_LIST_LICENSEE_BALANCE,
      exportBalanceEndpoint: this.$endpoints.FINANCE_EXPORT_LICENSEE_CURRENT_BALANCE,
      exportTransactionEndpoint: this.$endpoints.FINANCE_EXPORT_LICENSEE_BALANCE_TRANSACTION_HISTORY,
      balanceDetailRouteName: "licenseeBalanceDetail",
      exportFilenamePrefix: "商户余额",
      viewDetailPermission: FacilityActions.VIEW_LICENSEE_BALANCE_DETAIL,
    };
  },
};
</script>
